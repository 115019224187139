@import "../../_globalColor";

.podcast-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px;
}

.podcast-main-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.podcast-item {
  background-color: $lightBackground1;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 5px;
    font-size: 1.2em;
    color: #b388ff;
  }

  p {
    margin-bottom: 10px;
    color: #ffffff;
  }

  iframe {
    width: 100%;
    height: 152px;
  }
}

.podcast {
  width: 100%;
}

.subTitle {
  color: $subTitle;
}

.load-more-button {
  display: block;
  margin: 2rem auto 0;
  padding: 10px 20px;
  background-color: $buttonColor;
  color: $textColor;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $buttonHover;
  }
}

// Dark mode adjustments
.dark-mode {
  .podcast-item {
    background-color: $darkBackground;
  }

  .load-more-button {
    background-color: $buttonColor;
    &:hover {
      background-color: $buttonHover;
    }
  }
}

// Responsive design
@media (max-width: 768px) {
  .podcast-header-title {
    font-size: 30px;
    text-align: center;
    line-height: normal;
  }

  .podcast-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .podcast-main-div {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .podcast {
    width: 100%;
  }
}